import axiosInstance from './Api';

export default {

    async getPaymentIntentForProdService(payload) {
        const { prod_id } = payload;

        let response = await axiosInstance().get(`/payment/stripe/payment-intent-prod-service/${prod_id}`);

        return response;
    },

    async getPaymentIntentForExtraPay(payload) {
        const { prod_id } = payload;

        let response = await axiosInstance().get(`/payment/stripe/payment-intent-extra-pay/${prod_id}`);

        return response;
    },

    async verifyStripePayment(payload) {
        const { payment_intent } = payload;

        let response = await axiosInstance().post(`/payment/stripe/verify/${payment_intent}`);

        return response;
    },

    async getPayuHashForProdService(payload) {
        const { prod_id } = payload;

        let response = await axiosInstance().get(`/payment/payu/hash-prod-service/${prod_id}`);

        return response;
    },

    async getPayuHashForExtraPay(payload) {
        const { prod_id } = payload;

        let response = await axiosInstance().get(`/payment/payu/hash-prod-extra-pay/${prod_id}`);

        return response;
    },

    async verifyPayuPayment(payload) {
        const { payu_txnid } = payload;

        let response = await axiosInstance().get(`/payment/payu/verify/${payu_txnid}`);

        return response;
    },

    async createChildMerchant(payload) {

        let response = await axiosInstance().post(`/payment/payu/create-child-merchant`, payload);

        return response;
    },

}